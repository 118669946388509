.logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logoTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  
  .logoTitleDiv {
    margin: 10px 0px 50px;
  }
  
  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .logoTitle {
    font-family: Helvetica;
    font-size: 44px;
    font-weight: 400;
    line-height: 51px;
    color: rgba(255, 255, 255, 1);
  }
  
  .genAILogo {
    width: 60px;
    height: 60px;
  }
  
  .heading {
    font-family: Helvetica;
    font-size: 9px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    color: rgba(255, 255, 255, 1);
    font-size: 9px;
    font-weight: 400;
    text-align: center;
  }

  @media only screen and (max-width:768px) {

    .logoTitleContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 22.5px;
      }

    .genAILogo {
      width: 45px;
      height: 45px;
    }
  
    .logoTitleDiv {
      margin: 8px 0px 38px;
    }
  
    .logoTitle {
      font-size: 27px;
      line-height: 31px;
    }

    .heading {
        font-size: 6px;
        line-height: 6px;
    }
}