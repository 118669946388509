.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.field {
  width: 100%;
  padding: 8px 10px 8px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border-image: linear-gradient(92.82deg, #AE37AB 14.39%, #EDA3A8 111.49%); 
  border-image-slice: 1;
  border-image-width: 2px; */
  border-radius: 14px;
  border: 1.4px solid rgba(255, 255, 255, 1);
}

/* .field::after{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url(../../assets/images/svgs/border.png) no-repeat center center/100% 100%;
display: inline-block;
z-index: -1;
} */

.field textarea {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
  background: none;
  border: none;
  display: flex;
  box-sizing: border-box;
  /* margin-top: 10px; */
}

.autoResizableTextarea {
  height: 25px;
  max-height: 120px;
  overflow-y: hidden;
  resize: none; /* Disable textarea resizing by the user */
}

textarea::placeholder {
  color: rgba(163, 163, 168, 1);
}

.field button {
  width: 40px;
  height: 40px;
  border-radius: 7px;
  margin-left: 10px;
  /* background: linear-gradient(92.82deg, #AE37AB 14.39%, #EDA3A8 111.49%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)); */
  justify-content: center;
  align-items: center;
  background: #FFFFFF0F;

}

.field button img {
  width: 19px;
  height: 16px;
}

.iconStyle {
  margin-right: 10px;
}

.disclaimer {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  margin-top: 10px;
  color: rgba(163, 163, 168, 1);
}

/* .Input {
    background-color: burlywood;
}
  
.btn-primary {
    background-color: burlywood;
} */

/* Responsive styles for smaller screens */
@media only screen and (min-width: 520px) {
  /* .field {
    background-size: 450px 42px;
  } */

  .field button {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (min-width: 992px) {
  /* .field {
    background-size: 600px auto;
  } */
}

@media only screen and (min-width: 1240px) {
  /* .field {
    background-size: 790px auto;
  } */

  .field button {
    width: 40px;
    height: 40px;
  }

  .field button img {
    width: 19px;
    height: 16px;
  }
}

@media only screen and (max-width: 768px) {

  .field {
    z-index: 1;
    width: 100%;
    padding: 6px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border-image: linear-gradient(92.82deg, #AE37AB 14.39%, #EDA3A8 111.49%); 
  border-image-slice: 1;
  border-image-width: 2px; */
    border-radius: 8px;
    border: 2px solid white;
  }

  .field textarea {
    font-size: 12px;
    top: 2px;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .field button img {
    width: 16px;
    height: 13.5px;
  }

  .autoResizableTextarea {
    height: 21px;
    max-height: 96px;
    overflow-y: hidden;
    resize: none; /* Disable textarea resizing by the user */
  }

  .iconStyle {
    margin-right: 12px;
  }
}