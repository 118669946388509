.button {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}