.container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-top: 25px;
}

.titleContainer {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.icon {
    width: 24px;
    height: 24px;
    /* background-color: #3448ca; */
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
    color: white;
}

.dataContainer {
    margin-top: 12px;
}

.sectionItem {
    /* background-color: rgba(255, 255, 255, 0.05); */
    width: 100%;
    padding: 8px 8px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.66);
    background: rgba(255, 255, 255, 0.03);
}

.sectionClickItem {
    width: 100%;
    padding: 8px 8px;
    background: transparent;
    background: rgba(255, 255, 255, 0.03);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.66);
}

.sectionClickItem:hover {
    background: rgba(255, 255, 255, 0.3);
}

.itemTitle {
    font-family: Raleway;
    font-size: 12px;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(180, 189, 204, 1);
}