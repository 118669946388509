.ulListItem {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}

.genAIFlex {
  align-items: flex-start;
}

.userFlex {
  align-items: flex-end;
}

.dataItem {
  border-radius: 10px;
  padding: 10px 10px;
  background: #FFFFFF0F;
  width: fit-content;
}

.dataItemBorder {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 21px;
}

.userAvatar {
  justify-content: center;
  align-items: center;
}

.avatarContainer {
  /* height: 360px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.user {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: #FFFFFF;
}

.ai {
  letter-spacing: 0.28em;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  white-space: pre-wrap;
  color: #FFFFFF;
  width: fit-content;
}

.separator {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin: 6px 0;
}

.meta {
  margin-top: 6px;
}

.anchor, .anchor:hover, .anchor:visited, .anchor:active {
  text-decoration: underline;
  color: white;
}
 
.link {
  margin-left: 5px;
}
