.container {
  height: 107px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 87%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.genAILogo {
  width: 60px;
  height: 60px;
}

.logoTitleDiv {
  margin-left: 18px;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logoTitle {
  color: rgba(255, 255, 255, 1);
  font-family: Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 41px;
}

.heading {
  font-family: Helvetica;
  font-size: 7px;
  font-weight: 300;
  line-height: 8px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
}

.backButton {
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid rgba(82, 84, 87, 1);
}

.backTextStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.logoutTextStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-decoration: underline;
}

.logoutTextStyle:hover {
  color: rgba(255, 0, 0, 0.9);
}

.logout {
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  border-radius: 12px;
  background: transparent;
}

.chatButton {
  width: fit-content;
  height: 40px;
  padding: 0px 18px;
  border-radius: 23px;
  background: transparent;
  border: 2px solid rgba(244, 61, 61, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.chatButtonText {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}

.iconStyle {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.separator {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 1);
  opacity: 0.1;
}

.headerChatContainer {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .container {
    height: 81px;
    width: 100%;
  }

  .contentContainer {
    width: 90%;
  }

  .genAILogo {
    width: 45px;
    height: 45px;
  }

  .logoTitleDiv {
    margin-left: 14px;
  }

  .logoTitle {
    font-size: 27px;
    line-height: 31px;
  }

  .heading {
    font-size: 6px;
    line-height: 6px;
  }

  .chatButton {
    height: 30px;
    padding: 0px 14px;
    border-radius: 18px;
    background: transparent;
  }

  .chatButtonText {
    font-size: 12px;
    line-height: 12px;
  }

  .iconStyle {
    margin-right: 4px;
    width: 14px;
    height: 14px;
  }

  .backButton {
    height: 30px;
    padding: 6px 12px;
    border-radius: 9px;
  }

  .backTextStyle {
    font-size: 10.5px;
    line-height: 18px;
  }
}
