.container {
  display: flex;
  height: calc(100vh - var(--dynamic-height));
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-top: var(--dynamic-height);
  width: 100%;
  display: flex;
}

.backButtonContainer {
  width: 75%;
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
}

.backButton {
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid rgba(82, 84, 87, 1);
}

.logout {
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  border-radius: 12px;
  background: transparent;
}

.backTextStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.logoutTextStyle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-decoration: underline;
}

.logoutTextStyle:hover {
  color: rgba(255, 0, 0, 0.9);
}


.iconStyle {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.logoTitleDiv {
  margin: 10px 0px 50px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logoTitle {
  font-family: Helvetica;
  font-size: 44px;
  font-weight: 400;
  line-height: 51px;
  color: rgba(255, 255, 255, 1);
}

.genAILogo {
  width: 60px;
  height: 60px;
}

.heading {
  font-family: Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
  font-size: 9px;
  font-weight: 400;
  text-align: center;
}

.subHeading {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.chatButtons {
  width: 75%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 182px;
}

.icon {
  width: 24px;
  height: 22px;
  margin-right: 5px;
}

.genAIButton {
  background: linear-gradient(92.82deg, #ae37ab 14.39%, #eda3a8 111.49%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
}

.chatButton {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.hintSections {
  max-width: calc(66% + 60px);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.hintSection {
  max-width: 100%;
}

.section {
  width: 100%;
  margin-right: 20px;
}

.sectionLast {
  margin-right: 0px;
}

.input {
  width: 345px;
}

@media only screen and (max-width: 975px) {
  .container {
    height: fit-content;
  }

  .section {
    max-width: 90%;
    margin-bottom: 12px;
    margin-right: 0px;
  }

  .hintSections {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .hintSection {
    width: 75%;
  }
}

/* Responsive styles for smaller screens */
@media only screen and (min-width: 520px) {
  .input {
    width: 450px;
  }
}

@media only screen and (min-width: 992px) {
  .input {
    width: 600px;
  }

  .footer {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1240px) {
  .input {
    width: 790px;
  }
}

@media only screen and (max-width:768px) {

  .genAILogo {
    width: 45px;
    height: 45px;
  }

  .logoTitleDiv {
    margin: 8px 0px 38px;
  }

  .logoTitle {
    font-size: 33px;
    line-height: 39px;
  }

  .heading {
    font-size: 7px;
    line-height: 8px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    overflow-y: auto;
  }

  .backButtonContainer {
    width: 75%;
    margin-top: 26px;
  }
  
  .backButton {
    height: 30px;
    padding: 6px 12px;
    border-radius: 9px;
  }
  
  .backTextStyle {
    font-size: 10.5px;
    line-height: 18px;
  }
  
  .iconStyle {
    margin-right: 3px;
    width: 14px;
    height: 14px;
  }
}