.app {
  /* background: linear-gradient(30deg, rgba(13, 35, 33), rgba(13, 20, 32)); */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.chatScreen {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: var(--dynamic-height);
}