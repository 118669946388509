@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

@font-face {
  font-family: 'Fontawesome';
  src: url('../src/assets/fonts/fontawesome-webfont.woff') format('woff'),
    url('../src/assets/fonts/fontawesome-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/fontawesome-webfont.svg') format('svg'),
    url('../src/assets/fonts/fontawesome-webfont.ttf') format('truetype');
}

body {
  font-family: Raleway;
}

::-webkit-scrollbar {
  width: 10px;
}

.play_btn {
  position: absolute;
  /* background: url(images/btn.png) no-repeat 0 0; */
  width: 56px;
  height: 40px;
  z-index: 1;
  left: 45%;
  top: 35%
}

.video-testimonial-caption {
  /* background: url(images/text-box.png) no-repeat 0 0; */
  color: #fff;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1% 3%
}

.video_box {
  border: 4px solid #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .75);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .75);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .75)
}

#player {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 250px
}

.video_thumb {
  height: 250px;
  width: 100%
}

.video_box {
  position: relative;
  width: 400px;
  margin: 25px 0
}

.video_desc_box {
  margin: 25px 0
}

.font-14 {
  font-size: 14px !important;
  line-height: 150%
}

.font-12 {
  font-size: 12px !important;
  line-height: 150%
}

.progress-indicator {
  position: fixed;
  top: 100px;
  right: 15px;
  width: 100px;
  height: 100px
}

.progress-count {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100px;
  color: #0082ff
}

svg {
  position: absolute
}

circle {
  fill: rgba(255, 255, 255, .9)
}

svg .animated-circle {
  fill: transparent;
  stroke-width: 40px;
  stroke: #0a74da;
  stroke-dasharray: 126;
  stroke-dashoffset: 126
}

.video-thumbnail {
  position: relative
}

.video-thumbnail-play-icon {
  position: absolute;
  left: 40%;
  top: 40%
}

.archive_container {
  margin: 0 auto;
  max-width: 1170px
}

.tax-infographics-category #banner_box,
.post-type-archive-blog #banner_box,
.search-results #banner_box,
.tag #banner_box,
.single-blog #banner_box,
.category #banner_box,
.post-type-archive-infographics #banner_box,
.page-id-8750 #banner_box,
.page-id-324 #banner_box,
.page-id-8748 #banner_box,
.single-infographics #banner_box,
.author #banner_box {
  /* background: url(assets/images/blog-strip.png) no-repeat center center/cover; */
  position: relative;
  padding: 98px 0 32px;
  text-transform: uppercase
}

.syntaxhighlighter .toolbar {
  z-index: 9
}

.archive .common-post-container.section {
  margin-bottom: 50px
}

.post-type-archive-infographics img {
  max-width: 100%
}

.post-type-archive-infographics article img {
  width: 100%
}

.common-post-container .post-title {
  margin: 20px 0 8px;
  letter-spacing: 0;
  font-size: 34px;
  line-height: 39px;
  font-weight: 500;
  color: #2e373d
}

.single-infographics .post-title {
  margin-top: 0 !important
}

.common-post-container .post-title a {
  color: #2e373d
}

.common-post-container .post,
.common-post-container .infographic {
  margin-bottom: 60px;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  box-shadow: 0 -1px 5px 0 #ccc;
  -webkit-box-shadow: 0 -1px 5px 0 #ccc;
  position: relative;
  padding-bottom: 20px
}

.info-graphics-container .post {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 50px
}

.common-post-container .infographic .post-head,
.common-post-container .infographic p {
  padding: 0 5px
}

.common-post-container .infographic .post-title {
  padding: 10px 0
}

.common-post-container .post .post-head,
.common-post-container .post p {
  padding: 0 15px
}

.info-graphics-container .post .post-head,
.info-graphics-container .post p {
  padding: 0
}

.common-post-container .post .post-more-link {
  margin-left: 10px
}

.common-post-container .post img,
.common-post-container .figure img {
  width: 100%
}

.common-post-container .post-views-label {
  display: none
}

.common-post-container .post-views.entry-meta>span.post-views-icon.dashicons {
  border-right: 0;
  color: #f43d3d
}

.common-post-container .post-details span,
.common-post-container .post-details span a {
  text-transform: uppercase;
  color: #a8b1b4;
  font-size: 18px;
  line-height: 22px
}

.common-post-container .post-details span i {
  margin-top: -1px;
  color: #f43d3d
}

.post-media>* {
  margin-bottom: 0 !important
}

.archive .shareaholic-share-buttons-container ul.shareaholic-share-buttons {
  margin-left: 0 !important
}

.archive .post-head {
  margin-bottom: 0
}

.archive div[data-app=floated_share_buttons] {
  display: none !important
}

.common-post-container ul.social-icons li a {
  font-size: 22px !important;
  line-height: 26px;
  border-radius: 0;
  height: auto;
  padding: 6px 12px;
  width: auto;
  display: block
}

.common-post-container ul.social-icons li:first-child a {
  background: #1b95e0 !important;
  color: #fff
}

.common-post-container ul.social-icons li:nth-child(2) a {
  background: #df4a32 !important;
  color: #fff
}

.common-post-container ul.social-icons li:nth-child(3) a {
  background: #3a579a !important;
  color: #fff
}

.common-post-container ul.social-icons li:nth-child(4) a {
  background: #127bb6 !important;
  color: #fff
}

.common-post-container ul.social-icons li:first-child a:hover {
  background: #1b95e0 !important
}

.common-post-container ul.social-icons li:nth-child(2) a:hover {
  background: #df4a32 !important
}

.common-post-container ul.social-icons li:nth-child(3) a:hover {
  background: #3a579a !important
}

.common-post-container ul.social-icons li:nth-child(4) a:hover {
  background: #127bb6 !important
}

.common-post-container ul.social-icons li:first-child a:hover .fa {
  color: #fff !important
}

.common-post-container ul.social-icons li:nth-child(2) a:hover .fa {
  color: #fff !important
}

.common-post-container ul.social-icons li:nth-child(3) a:hover .fa {
  color: #fff !important
}

.common-post-container ul.social-icons li:nth-child(4) a:hover .fa {
  color: #fff !important
}

.common-post-container ul.social-icons li a:hover {
  opacity: .8
}

.common-post-container ul.social-icons li {
  vertical-align: top
}

.common-post-container ul.social-icons li:last-child {
  width: 40px;
  height: 38px;
  position: relative;
  text-align: center;
  border: 1px solid #df4a32;
  padding: 8px 0
}

.post-share ul.social-icons.social-sticky li:last-child {
  width: 100%
}

.common-post-container .post p {
  font-size: 16px;
  line-height: 26px;
  color: #777
}

.single-blog .ts-fab-latest-posts-link {
  display: none
}

#case-study-form {
  margin-top: 170px
}

.single-case-study #home-section-wrapper {
  padding: 110px 0 0;
  background-size: cover;
  background-position: center center
}

.single-blog .common-post-container h1,
.single-blog .common-post-container h2 {
  color: #2e373d
}

.single .common-post-container .post-tags a {
  padding: 0 5px;
  color: #d95b44
}

.single .common-post-container .post-tags a:hover {
  color: #f90
}

.single-blog .figure {
  width: 100%
}

.common-post-container .sidebar .widget.widget_categories {
  width: 300px
}

.common-post-container .sidebar .widget img {
  width: 304px
}

.common-post-container .sidebar .widget.widget_categories h2.widgettitle {
  /* background: url(assets/images/blog_sidebar_cat.png) no-repeat; */
  background-size: cover;
  background-position: center center
}

.common-post-container .sidebar .widget.widget_categories h2.widgettitle {
  /* background: url("assets/images/blog_sidebar_cat.png") no-repeat scroll center center/cover; */
  display: block;
  font-size: 19px;
  letter-spacing: 0;
  line-height: 45px;
  padding-left: 55px;
  margin-bottom: 20px
}

.common-post-container .sidebar .widget {
  margin: 0 0 15px
}

.common-post-container .sidebar .widget_categories ul li {
  border-bottom: 1px dotted #e2e2e2;
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 17px
}

.common-post-container .sidebar .widget_categories ul li a {
  color: #666;
  transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
  padding: 10px 5px;
  display: block
}

.common-post-container .sidebar .widget_categories ul li:hover {
  background: #fafafa
}

.common-post-container .sidebar .widget_categories ul li a:hover {
  color: #bd4c4c;
  padding-left: 10px
}

.widget ul li::after {
  color: #ccc;
  content: "\f054";
  font-family: FontAwesome;
  position: absolute;
  right: 10px;
  top: 15px;
  color: #bbb;
  font-size: 8px
}

.info-graphics-container article {
  margin-bottom: 40px
}

.sidebar-related-post {
  width: 300px
}

.common-post-container .sidebar-related-post a,
.common-post-container .sidebar-related-post span {
  color: #666;
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  margin: 10px 0
}

.single-infographics article.post {
  box-shadow: none
}

.archive_container h2 {
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 36px;
  margin: 10px 0
}

.archive_container h3 {
  font-size: 22px;
  line-height: 28px
}

.archive_container p {
  text-align: justify;
  line-height: 27px
}

.archive_container li {
  position: relative;
  list-style: none;
  padding-bottom: 10px
}

.archive_container .micro-list li:after {
  color: #f73c3c;
  content: "\f0a4";
  font-family: fontawesome;
  font-size: 25px;
  height: 20px;
  left: -35px;
  position: absolute;
  top: -8px;
  width: 20px
}

.archive_container .post-body a {
  color: #f73c3c;
  position: relative;
  display: inline-block
}

.archive_container .post-body a:after {
  background: #f73c3c;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  transition: all .3s ease 0s;
  -webkit-transition: all .3s ease 0s;
  width: 0
}

.archive_container a:hover:after {
  width: 100%
}

.archive_container .previous a,
.archive_container .next a {
  background-color: #eee;
  background-image: -moz-linear-gradient(center top, #f7f7f7, #eee);
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 2px 2px 4px -1px rgba(221, 0, 9, .55);
  color: #585858;
  display: block;
  float: left;
  margin-right: 6px;
  padding: 6px 11px;
  text-decoration: none
}

.archive_container .previous:hover a:after,
.archive_container .next:hover a:after {
  display: none
}

.archive_container .previous:hover a,
.archive_container .next:hover a {
  border-color: transparent;
  color: #dd0009
}

.archive_container .next {
  float: right
}

.archive_container .post-body .ts-fab-wrapper a:after {
  content: none
}

.sidebar-slow-scroll-class.active {
  position: fixed;
  margin-left: 800px
}

.post-share .social-sticky {
  left: 0;
  position: fixed;
  z-index: 991
}

.post-share .social-sticky li {
  display: block
}

.related-article-box {
  width: 100%;
  padding: 25px 0;
  float: left
}

.related-article-box ul.related-article {
  width: 100%;
  padding-left: 0
}

.related-article-box ul.related-article li {
  width: 48%;
  float: left;
  padding-bottom: 10px;
  font-size: 20px;
  line-height: 25px;
  color: #214cb8;
  font-weight: 600
}

.related-article-box ul.related-article li a {
  color: #214cb8;
  text-decoration: none
}

.related-article-box ul.related-article li a:after {
  background: #214cb8;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  transition: all .3s ease 0s;
  -webkit-transition: all .3s ease 0s;
  width: 0
}

.related-article-box ul.related-article li a:hover:after {
  width: 100%
}

@media all and (max-width: 1279px) {
  .sidebar-slow-scroll-class.active {
    margin-left: 683px
  }

  .single-blog .wv-wrapper.wv-circle-small {
    margin-left: 0 !important;
    margin-top: -20px !important
  }
}

@media all and (max-width: 991px) {
  .sidebar-slow-scroll-class {
    position: static !important;
    margin: 0 !important
  }
}

@media all and (max-width: 991px) and (min-width:768px) {

  .archive_container .col-md-8,
  .archive_container .sidebar {
    padding: 0 50px
  }
}

@media only screen and (max-width: 1200px) {
  .archive_container {
    padding: 0 15px
  }

  .common-post-container .post-title {
    font-size: 26px;
    line-height: 30px
  }
}

@media only screen and (max-width: 992px) {
  .info-graphics-container img {
    width: 100%
  }

  .textwidget iframe {
    display: block !important
  }

  .related-article-box ul.related-article li a {
    font-size: 17px;
    line-height: 21px
  }

  .field textarea {
    font-size: 14pc;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog .post-share .social-sticky {
    display: none
  }

  .post-share .social-sticky a {
    padding: 5px !important;
    font-size: 18px;
    line-height: 22px !important
  }

  .post-share .social-sticky a .fa {
    font-size: 18px !important
  }

  .common-post-container .post img {
    height: auto;
    max-width: 100%;
    width: auto
  }

  .common-post-container .post-title {
    font-size: 22px;
    line-height: 26px
  }

  .common-post-container .post-details span,
  .common-post-container .post-details span a {
    color: #a8b1b4;
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    padding-right: 5px;
    margin-right: 5px
  }

  .common-post-container .sidebar .widget img {
    width: auto
  }

  .sidebar-related-post {
    width: 100%
  }

  .sidebar .widget>div {
    height: auto !important;
    width: 100% !important
  }

  .archive_container iframe {
    width: 100% !important
  }

  .related-article-box ul.related-article li {
    width: 100%
  }

  .single-blog .syntaxhighlighter {
    width: 100% !important;
    overflow-x: auto !important;
    display: block
  }

  .single-blog .syntaxhighlighter .line .number {
    width: 2em !important
  }

  .single-blog .syntaxhighlighter .line.alt2 {
    background-color: transparent !important
  }
}

.app-content-container {
  background: #1F1F1F;
}

.footer {
  padding-top: 40px;
}

.title {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: white;
  text-align: left;
  padding: 10px 60px;
}

.error {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: red;
  text-align: left;
  padding: 10px 60px;
}

@media (max-width: 1400px) {
  .ubermenu-main .ubermenu-row {
    max-width: 1000px;
  }
}

@media screen and (min-width: 520px) {
  .Home_chatScreen__eGCGO .InputSearchComponent_field__I15TM {
    background-size: 100% 40px;
  }
}

@media screen and (min-width: 992px) {
  .Home_chatScreen__eGCGO .InputSearchComponent_field__I15TM {
    background-size: 100%;
  }

}

.Home_chatScreen__eGCGO .InputSearchComponent_field__I15TM button {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.10) !important;
  border: 2px solid rgb(92 92 92 / 69%);
}