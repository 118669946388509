.container {
    width: 87%;
    height: calc(100vh - var(--dynamic-height) - 100px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0px;
}

.appContainer {
    width: 70%;
    height: 100%;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chatListContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.hintList {
    list-style-type: none;
}

.hintSections {
    width: 25%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
}

.section {
    margin-bottom: 10px;
}

.query {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color: gray;
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 30px;
    /* flex-direction: column; */
}

.loader {
    width: 210px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.title {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: white;
    text-align: left;
}

@media only screen and (max-width: 1100px) {
    .container {
        width: 90%;
        display: flex;
        flex-direction: column;
        height: fit-content;
    }

    .appContainer {
        width: 100%;
        height: calc(100vh - var(--dynamic-height) - 100px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

}

/* Responsive styles for smaller screens */
@media only screen and (max-width: 768px) {

    .container {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .appContainer {
        width: 100%;
        height: calc(100vh - var(--dynamic-height) - 76px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .loader {
        width: 180px;
    }
}