.app {
    /* background: linear-gradient(30deg, rgba(13, 35, 33), rgba(13, 20, 32)); */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-top: var(--dynamic-height);
}

.container {
    align-items: center;
}

.disclaimer {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: rgba(255, 255, 255, 1);
}

.appTitle {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: rgba(255, 255, 255, 1);
}

.link {
    width: 85%;
    height: 60px;
    margin-bottom: 22px;
}

.caseStudyDiv {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    flex-direction: row;
}

.linkContainer {
    width: 50%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.caseStudy {
    width: 50%;
    height: 60px;
}

.chatButton {
    width: 100%;
    height: 100%;
    background: rgba(211, 5, 0, 1);
    border-radius: 14px;
}


.buttonTitle {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 5px;
}

.homeButton {
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.chatIcon {
    width: 24;
    aspect-ratio: 1;
    margin-right: 5px;
}

.buttonsDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.anchor, .anchor:hover, .anchor:visited, .anchor:active {
    text-decoration: underline;
    color: white;
}
   
.link {
    margin-left: 5px;
}
  

@media only screen and (max-width:768px) {

    .disclaimer {
        font-size: 14px;
        line-height: 16px;
    }
    
    .appTitle {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 16px;
    }

    .buttonTitle {
        font-size: 14px;
        line-height: 16px;
    }
}