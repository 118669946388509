.container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 25px 25px;
  /* border-color: rgba(255, 255, 255, 0.1); */
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(28, 28, 28, 0.4);
  /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)); */
}

.titleContainer {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  /* background-color: #3448ca; */
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 20px;
  color: white;
}

.dataContainer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.sectionItem {
  /* background-color: rgba(255, 255, 255, 0.05); */
  border-radius: 6px;
  padding: 6px 10px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.03);
}

.sectionClickItem {
  border-radius: 6px;
  padding: 6px 10px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.03);
}

.sectionClickItem:hover {
  background: rgba(255, 255, 255, 0.3);
}

.itemTitle {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(180, 189, 204, 1);
}
